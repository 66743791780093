import { useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from 'react-query'

import { apiClient_UNSTABLE } from '../api/api-client-unstable'
import { AuditLogGetResponse, AuditLogShowResponse } from '../api/data-providers/runbook-types'
import { apiClient, ApiError } from '../api'
import { serverQueryObjectToServerQueryString } from 'main/components/shared/filter/filter-params'

export const downloadAuditLogCsv = async (runbookId: number | string) => {
  const { data } = await apiClient_UNSTABLE.post<ArrayBuffer>(`runbooks/${runbookId}/audit_csv`, undefined, {
    responseType: 'arraybuffer'
  })

  return data
}

const getAuditLogItem = async (logItemId: number) => {
  const { data } = await apiClient_UNSTABLE.get<AuditLogShowResponse>(`action_logs/${logItemId}`)

  return data
}

export const useGetAuditLogItem = (logItemId: number, options?: UseQueryOptions<AuditLogShowResponse, ApiError>) =>
  useQuery<AuditLogShowResponse, ApiError>(
    ['audit-log-item', logItemId],
    async () => await getAuditLogItem(logItemId),
    options
  )

export function useRunbookAuditLog({
  runbookId,
  params = {},
  options = { enabled: true }
}: {
  runbookId?: string | number
  params?: Record<string, any>
  options?: UseInfiniteQueryOptions<AuditLogGetResponse, ApiError>
}) {
  const { limit = 50, ...restParams } = params
  const serverParams = serverQueryObjectToServerQueryString({
    queryObject: restParams,
    defaults: {
      limit,
      runbook_id: runbookId
    }
  })

  const getAuditLogs = async (offset: number = 0) => {
    const { data } = await apiClient.get<AuditLogGetResponse>({
      url: `action_logs${serverParams}&offset=${offset}`
    })

    return data
  }

  return useInfiniteQuery<AuditLogGetResponse, ApiError>(
    ['runbook-audit-log', String(runbookId), serverParams],
    ({ pageParam = 0 }) => getAuditLogs(pageParam),
    {
      getNextPageParam: (lastGroup, allGroups) => {
        const totalResultCount = allGroups[0]?.meta?.total_results
        const lastOffset = allGroups.length * limit
        return totalResultCount > limit && totalResultCount > lastOffset ? lastOffset : undefined
      },

      ...options,
      enabled: options.enabled && !!runbookId
    }
  )
}
