import { memo, ReactNode } from 'react'
import { useIsAuditLogMatch } from 'single-spa/route-matchers'

import { useDefaultLayout } from '@cutover/react-ui'
import { StreamEditPanel, TaskEditPanel } from './right-panels'
import { UserOrTeamDetailsPanel } from './right-panels/user-team-details-panel'
import { RunbookFilter } from './runbook-filter/runbook-filter'
import { RunbookHeader } from './runbook-header/runbook-header'
import { RunbookRightNavigation } from './runbook-right-navigation/runbook-right-navigation'
import { RunbookSubHeader } from './runbook-sub-header/runbook-sub-header'
import { RunbookBanner } from '../layout/runbook-banner'
import { Layout } from 'main/components/layout'
import { RunbookCopiesPanel, RunbookLinkedPanel } from '../workspace/right-panels'
import { RunbookEditPanelTaskList } from './right-panels/runbook-edit-panel-task-list'
import {
  useActiveRightPanelValueType,
  useCloseRightPanelOnMount,
  useRunbookRightPanelOpenByDefault
} from 'main/components/layout/right-panel'
import { AiSuggestionsPanel } from './right-panels/ai-suggestions/ai-suggestions-panel'
import { CommentsPanel } from './right-panels/comments-panel/comments-panel'
import { TasksBulkEditPanel } from './right-panels/tasks-bulk-edit-panel/tasks-bulk-edit-panel'
import { RunbookProgressionModals } from './modals/runbook-progression-modals'
import { TemplateWorkflowModals } from './modals/template-workflow/template-workflow-modals'
import { RunbookDashboardSchedulePanelWrapper as RunbookDashboardSchedulePanel } from './right-panels/dashboard-schedule/runbook-dashboard-schedule-panel-wrapper'
import { RunbookIntegrationsPanel } from './right-panels/runbook-integrations-panel/runbook-integrations-panel'
import { AuditLogItemPanel } from './right-panels/audit-log-panel/audit-log-panel'
import { AuditLogFilter } from './pages/audit-log/audit-log-filters'
import { ChangeRequestsPanel } from './right-panels/change-requests/change-requests-panel'

export const RunbookLayout = memo(({ children }: { children?: ReactNode }) => {
  const isRightPanelOpen = useRunbookRightPanelOpenByDefault()
  useCloseRightPanelOnMount({ triggerAngular: true, isRightPanelOpen })
  const isAuditLog = useIsAuditLogMatch()

  useDefaultLayout({
    filterPanel: true,
    subHeader: true,
    rightNav: true,
    isRightPanelOpen
  })

  return (
    <>
      <Layout
        header={<RunbookHeader />}
        subHeader={<RunbookSubHeader />}
        banner={<RunbookBanner />}
        filter={isAuditLog ? <AuditLogFilter /> : <RunbookFilter />}
        rightNavigation={<RunbookRightNavigation />}
        rightPanels={<RightPanels />}
      >
        {children}
      </Layout>
      <TemplateWorkflowModals />
      <RunbookProgressionModals />
    </>
  )
})

const RightPanels = memo(() => {
  const panelType = useActiveRightPanelValueType()

  switch (panelType) {
    case 'stream-edit':
      return <StreamEditPanel />
    case 'task-edit':
      return <TaskEditPanel />
    case 'tasks-bulk-edit':
      return <TasksBulkEditPanel />
    case 'runbook-edit':
      return <RunbookEditPanelTaskList />
    case 'runbook-copies':
      return <RunbookCopiesPanel />
    case 'runbook-linked':
      return <RunbookLinkedPanel />
    case 'user-team-details-edit':
      return <UserOrTeamDetailsPanel />
    case 'ai-suggestions-list':
      return <AiSuggestionsPanel />
    case 'runbook-comments':
      return <CommentsPanel />
    case 'audit-log-item':
      return <AuditLogItemPanel />
    case 'runbook-dashboard-schedule':
      return <RunbookDashboardSchedulePanel />
    case 'runbook-integrations':
      return <RunbookIntegrationsPanel />
    case 'runbook-change-requests':
      return <ChangeRequestsPanel />
    default:
      return null
  }
})
