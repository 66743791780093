import { useCallback, useMemo } from 'react'
import { format as formatDate, fromUnixTime } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { Box, ColumnConfig, NoResourceFound, Table, Text } from '@cutover/react-ui'
import { ActiveRunbookModel, RunbookViewModel } from 'main/data-access'
import { useAppliedFilters, useClearAllFilterState } from 'main/recoil/data-access'
import { useCloseRightPanelOnMount, useToggleRightPanel } from 'main/components/layout/right-panel'
import { AuditLogSubHeader } from './audit-log-subheader'
import { useLanguage } from 'main/services/hooks'
import { AuditLogType } from 'main/services/queries/types'
import { useRunbookAuditLog } from 'main/services/queries/use-audit-log'

export const AuditLog = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'auditLog' })
  const toggleDetailPanel = useToggleRightPanel('audit-log-item')
  const activeTimezone = RunbookViewModel.useGet('activeTimezone')
  const runbookId = ActiveRunbookModel.useId()
  const filters = useAppliedFilters()
  const { isFetchingNextPage, data, fetchNextPage, isLoading } = useRunbookAuditLog({
    runbookId,
    params: filters,
    options: { enabled: !!runbookId }
  })

  useCloseRightPanelOnMount()
  const clearAllFilters = useClearAllFilterState()
  const auditLogs = useMemo(() => data?.pages.flatMap(page => page.action_logs), [data?.pages])

  const calcChangeDescription = (item: AuditLogType) => {
    if (displaySummaryOnly(item)) {
      return <Text>{item.summary}</Text>
    }

    // Note: this logic feels like a hack, needs to be consistent
    const shouldDisplaySummary =
      item.object_type !== 'Runbook' ||
      (item.object_type === 'Runbook' && (item.event_type === 'merged' || item.summary === 'admins'))

    const color = getColor(item)

    return (
      <Text
        color={color}
        css={`
          display: inline-flex;
          flex-direction: row;
          column-gap: 6px;
        `}
      >
        <Text>{item.object_type}</Text>
        {shouldDisplaySummary && <Text truncate="tip">{item.summary}</Text>}
        {item.object_type === 'Runbook' && item.event_type === 'created from template' && (
          <>
            <Text>{t('createdFrom', { templateName: item.runbook_template_name })}</Text>
            <Text>{t('version', { versionNumber: item.template_version_number })}</Text>
          </>
        )}
        {item.event_type !== 'created from template' && <Text weight="bold">{item.event_type}</Text>}
      </Text>
    )
  }

  const getColor = (item: AuditLogType) => {
    switch (item.event_type) {
      case 'created':
      case 'created from template':
      case 'multiples created':
      case 'bulk create':
      case 'merged':
      case 'skipped':
      case 'completed':
      case 'in progress':
        return 'primary'
      case 'cancelled':
      case 'created from unapproved template':
      case 'deleted':
      case 'bulk delete':
        return 'error'
      case 'paused':
        return 'warning'
      default:
        return 'text'
    }
  }

  // Note: replicates angular logic but seems wrong to deal with these specific actions differently
  const displaySummaryOnly = (item: AuditLogType) => {
    return (
      [
        'linked',
        'unlinked',
        'linked task started',
        'child runbook started',
        'child runbook cancelled',
        'linked task cancelled',
        'linked task skipped',
        'child runbook skipped',
        'linked task marked finishable',
        'child runbook completed',
        'child runbook paused',
        'linked task paused',
        'linked task abandoned',
        'restore the linked task runbook to a template'
      ].indexOf(item.event_type) !== -1
    )
  }

  // NOTE: this should be somewhere central? Too many similarly named date functions
  const parseDateTime = (timestamp: number, tz?: string | null) => {
    const date = fromUnixTime(timestamp as number)
    const zonedDate = tz ? utcToZonedTime(date, tz) : date
    return formatDate(zonedDate, 'd LLL yyyy HH:mm')
  }

  const columns: ColumnConfig<AuditLogType>[] = useMemo(
    () => [
      {
        property: 'created_at',
        header: t('date', { timezone: activeTimezone ? `(${activeTimezone})` : '' }),
        render: datum => {
          return parseDateTime(datum.created_at, activeTimezone)
        },
        size: activeTimezone ? '200px' : '100px',
        sortable: false
      },
      {
        property: 'object_type',
        header: t('changeSummary'),
        render: datum => calcChangeDescription(datum),
        size: '250px',
        sortable: false
      },
      {
        property: 'author',
        header: t('author'),
        render: datum => {
          return datum.author_name
        },
        size: '100px',
        sortable: false
      }
    ],
    []
  )

  const handleClickRow = useCallback(
    ({ datum }: { datum: any }) => {
      toggleDetailPanel({ logItemId: datum?.id as number })
    },
    [toggleDetailPanel]
  )

  return (
    <AuditLogSubHeader logsCount={data?.pages[0].meta.total_results}>
      <Box pad={{ right: '16px' }} data-testid="audit-log">
        {!isLoading && auditLogs?.length === 0 ? (
          <NoResourceFound context="audit log" clearAllFilters={clearAllFilters} />
        ) : (
          <Table
            replace
            columns={columns}
            data={auditLogs ?? undefined}
            onMore={fetchNextPage}
            sortable="server"
            isLoadingMore={isFetchingNextPage}
            isLoading={isLoading}
            onClickRow={handleClickRow}
          />
        )}
      </Box>
    </AuditLogSubHeader>
  )
}
