import { memo, useCallback, useMemo } from 'react'
import { unescape } from 'lodash'
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Box, IconButton, RunbookTypeIcon, TabGroup, TabItem, Text, TextLink } from '@cutover/react-ui'
import { RunbookControl } from './runbook-control'
import { RunbookElapsedTime } from './runbook-elapsed-time'
import { RunbookToolbar } from './runbook-toolbar'
import { DisplayType } from '../runbook-sub-header/view-navigation'
import { useAppliedFiltersCallback } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { routes } from 'main/services/routing/routes'
import {
  ActiveAccountModel,
  ActiveDashboardModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  CurrentRunbookVersionModel,
  DashboardModel,
  RunbookViewModel
} from 'main/data-access'
import { serverQueryObjectToBrowserQueryString } from 'main/components/shared/filter/filter-params'
import { useRouting } from 'main/services/routing'

export const RunbookHeader = () => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook' })
  const navigate = useNavigate()
  const rbvId = ActiveRunbookVersionModel.useId()
  const { id: currentRbvId } = CurrentRunbookVersionModel.useGet()
  const isCurrentRunbookVersion = rbvId === currentRbvId
  const { toRunbook } = useRouting()

  const { slug: accountId } = ActiveAccountModel.useGet()

  const {
    template_type: templateType,
    name,
    linked_runbook_details: linkedRunbookDetails
  } = ActiveRunbookModel.useGet()

  const runbookType = ActiveRunbookModel.useRunbookType()
  const isTemplate = templateType === 'default' || templateType === 'snippet'

  const parentRunbookId = linkedRunbookDetails?.linked_resource?.id
  const canViewParentRunbook =
    linkedRunbookDetails?.linked_resource_permissions?.read &&
    !!parentRunbookId &&
    linkedRunbookDetails?.linked_resource?.archived !== true
  const parentRunbookLink = !!parentRunbookId
    ? '#' + toRunbook({ accountSlug: accountId as string, runbookId: parentRunbookId })
    : undefined

  return (
    <Box direction="row" fill justify="between" align="center">
      <Box direction="row" align="center" gap="6px">
        <Box height={{ min: '40px' }} width={{ min: '40px' }}>
          {/* TODO: go back to the runbook list with the most recent set of filters applied if navigated to the runbook
          from the runbooks list */}
          <IconButton
            tertiary
            icon="arrow-back"
            label={t('back')}
            onClick={() =>
              navigate(
                `/app/${accountId}/runbooks/list${
                  isTemplate ? `?sort_by=updated_at&sort_dir=desc&template_type=${templateType}` : ''
                }`
              )
            }
          />
        </Box>
        <Box height={{ min: '40px' }} width={{ min: '40px' }}>
          <RunbookTypeIcon
            icon={runbookType?.icon_name || 'getting-started'}
            color={runbookType?.icon_color || 'primary'}
            css="margin-right:8px;"
            template={isTemplate}
          />
        </Box>
        <Box justify="between" fill>
          <Box
            direction="row"
            gap="xxsmall"
            css={`
              position: relative;
              top: 5px;
            `}
            flex={false}
          >
            <StyledRunbookTitle data-testid="runbook-title">
              {linkedRunbookDetails?.linked_resource && !isTemplate && (
                <>
                  {canViewParentRunbook ? (
                    <TextLink
                      href={parentRunbookLink}
                      label={unescape(linkedRunbookDetails.linked_resource.name)}
                      openInNew
                    />
                  ) : (
                    <Text truncate="tip" css="max-width: 160px">
                      {unescape(linkedRunbookDetails.linked_resource.name)}
                      {linkedRunbookDetails.linked_resource.archived === true ? t('archived') : ''}
                    </Text>
                  )}
                  <Text>&nbsp;{'/'}&nbsp;</Text>
                </>
              )}
              <Text truncate="tip">{unescape(name)}</Text>
            </StyledRunbookTitle>
            <RunbookToolbar />
          </Box>
          <Box
            css={`
              white-space: nowrap;
              overflow-y: hidden;
              overflow-x: auto;
            `}
          >
            <Tabs accountId={accountId} />
          </Box>
        </Box>
      </Box>
      {isCurrentRunbookVersion && (
        <Box direction="row" align="center" flex={false}>
          <Box pad={{ left: 'xsmall', right: 'xsmall' }}>
            <RunbookElapsedTime />
          </Box>
          <Box direction="row" css="flex-basis: 52px;" flex={false}>
            <RunbookControl />
          </Box>
        </Box>
      )}
    </Box>
  )
}

type ActiveTab = DisplayType | 'pir'

const Tabs = memo(({ accountId }: { accountId: string }) => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook.tabs' })
  const location = useLocation()
  const navigate = useNavigate()
  const getAppliedFilters = useAppliedFiltersCallback()
  const { template_type: templateType } = ActiveRunbookModel.useGet()
  const isSnippet = templateType === 'snippet'

  const { can: showPIRLink } = RunbookViewModel.usePermission('show:pir-link')
  const activeDashboard = ActiveDashboardModel.useGet()
  const pirDashboard = DashboardModel.useGetBy({ key: 'pir' })
  const runbookDashboards = DashboardModel.useGetAll({ scope: 'dashboard' })
  const runbookPages = DashboardModel.useGetAll({ scope: 'pages' })

  const { runbookId, runbookVersionId } = useParams()

  const reactDashboardMatch = useMatch(
    '/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/dashboard/:dashboardId'
  )
  const reactPageMatch = useMatch('/app/:accountId/runbooks/:runbookId/:runbookVersionId/page/content/:dashboardId')
  const displayMatch = useMatch('/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/:displayType/*')

  const dashboardId = reactDashboardMatch?.params.dashboardId
  const pageId = reactPageMatch?.params.dashboardId
  const activeTab = (
    pirDashboard?.id === activeDashboard?.id ? 'pir' : pageId ? 'page' : displayMatch?.params.displayType
  ) as ActiveTab

  const getFilterParams = async () => {
    const appliedFilters = await getAppliedFilters()
    // remove user list & audit log params from applied filters when navigating from users page
    const {
      q: _,
      author_id: __,
      created_after: ___,
      created_before: ____,
      object_type: _____,
      task_id: ______,
      ...restAppliedFilter
    } = appliedFilters

    return serverQueryObjectToBrowserQueryString({
      queryObject:
        location.pathname.includes('users') || location.pathname.includes('auditlog')
          ? restAppliedFilter
          : appliedFilters
    })
  }

  const getRunbookDashboardMenuItems = useCallback(
    () =>
      runbookDashboards.map(dashboard => ({
        label: dashboard.name,
        selected: dashboard.id === Number(dashboardId),
        onClick: async () => {
          const filterParams = await getFilterParams()
          navigate(
            routes.toDashboard({
              accountSlug: accountId,
              runbookId: Number(runbookId),
              versionId: Number(runbookVersionId),
              dashboardId: dashboard.id,
              params: filterParams
            })
          )
        }
      })),
    [runbookDashboards, dashboardId, accountId, runbookId, runbookVersionId, getFilterParams]
  )

  const getRunbookPageMenuItems = useCallback(
    () =>
      runbookPages.map(dashboard => ({
        label: dashboard.name,
        selected: dashboard.id === Number(pageId),
        onClick: async () => {
          const filterParams = await getFilterParams()
          navigate(
            routes.toPage({
              accountSlug: accountId,
              runbookId: Number(runbookId),
              versionId: Number(runbookVersionId),
              dashboardId: dashboard.id,
              params: filterParams
            })
          )
        }
      })),
    [runbookPages, getFilterParams, accountId, runbookVersionId, runbookId, pageId]
  )

  const items = useMemo(() => {
    return [
      !!runbookPages.length &&
        !isSnippet && {
          label: t('pages'),
          active: activeTab === 'page',
          menuItems: getRunbookPageMenuItems()
        },

      !!runbookDashboards.length &&
        !isSnippet && {
          label: t('dashboards'),
          active: activeTab === 'dashboard',
          menuItems: getRunbookDashboardMenuItems()
        },

      showPIRLink &&
        !isSnippet && {
          label: 'PIR',
          active: activeTab === 'pir',
          onClick: () =>
            navigate(
              routes.toDashboard({
                accountSlug: accountId,
                runbookId: Number(runbookId),
                versionId: Number(runbookVersionId),
                dashboardId: pirDashboard?.id
              })
            )
        },
      {
        label: t('tasks'),
        active: ['list', 'table', 'map'].includes(activeTab),
        onClick: async () => {
          const filterParams = await getFilterParams()
          navigate(
            routes.toRunbook({
              accountSlug: accountId,
              runbookId: Number(runbookId),
              versionId: Number(runbookVersionId),
              params: filterParams
            })
          )
        }
      }
    ].filter(Boolean) as TabItem[]
  }, [
    accountId,
    activeTab,
    dashboardId,
    getFilterParams,
    getRunbookDashboardMenuItems,
    getRunbookPageMenuItems,
    runbookDashboards,
    runbookId,
    runbookPages,
    runbookVersionId,
    t
  ])

  return <TabGroup items={items} />
})

const StyledRunbookTitle = styled(Box)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  a {
    max-width: 160px;
    font-weight: 600;
  }
  span {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
  }
`
