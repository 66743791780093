import { useEffect, useMemo, useRef, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import queryString from 'query-string'

import { Box, Button, EditPanel, IconButton, Message } from '@cutover/react-ui'
import { RunbookEditForm, RunbookEditFormType } from './runbook-edit-form'
import { useCustomFieldForm } from 'main/components/shared/custom-field-form'
import { buildDefaultFieldValues } from 'main/components/shared/custom-field-form/custom-field-form-helper'
import { useIsTemplateLocked } from 'main/components/shared/hooks/runbook'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import {
  Account,
  CustomField,
  CustomFieldGroup,
  CustomFieldUser,
  RunbookEditRunbook,
  RunbookTypeType,
  StreamListStream,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'
import { RunbookPermittedResource } from 'main/services/queries/use-permitted-resources'
import { RunbookUpdatePayload } from 'main/services/queries/use-update-runbook'
import { useRouting } from 'main/services/routing/hooks'

export type RunbookSubmitData = {
  data: RunbookEditFormType
  payload: RunbookUpdatePayload['runbook']
  timezone: string | null
}

type RunbookEditProps = {
  runbook: RunbookEditRunbook
  runbookType: RunbookTypeType
  runbookTypes: RunbookTypeType[]
  taskLookup: Record<string, TaskListTask>
  taskTypeLookup: Record<string, TaskType>
  streamLookup: Record<string, StreamListStream>
  permittedProjectsData: RunbookPermittedResource
  isLoading?: boolean
  isRunbookPage: boolean
  readOnly: boolean
  account?: Account
  customFieldProps: {
    customFieldsLookup?: Record<number, CustomField>
    customFieldUsers?: CustomFieldUser[]
    customFieldGroupsLookup?: Record<number, CustomFieldGroup>
    customFieldGroups?: CustomFieldGroup[]
  }
  isSubmitting: boolean
  apiErrorMessage?: string | string[] | null
  onSubmit: ({ data, payload, timezone }: RunbookSubmitData) => void
}

export const RunbookEdit = ({
  runbook,
  runbookType,
  runbookTypes,
  taskLookup,
  taskTypeLookup,
  streamLookup,
  permittedProjectsData,
  isLoading,
  isRunbookPage,
  readOnly,
  account,
  customFieldProps,
  isSubmitting,
  apiErrorMessage,
  onSubmit
}: RunbookEditProps) => {
  const [errorMessage, setErrorMessage] = useState<string | string[] | null>(null)
  const { t } = useLanguage('runbook', { keyPrefix: 'editPanel' })
  const location = useLocation()
  const navigate = useNavigate()
  const { toRunbook } = useRouting()
  const [descriptionGenerated, setDescriptionGenerated] = useState(runbook.ai_generated_description)
  const { openRightPanel, closeRightPanel } = useSetActiveRightPanelState()

  const runbookId = runbook.id
  const templateType = runbook.template_type

  const { customFieldsLookup, customFieldUsers, customFieldGroupsLookup, customFieldGroups } = customFieldProps
  const {
    initialFieldValues,
    fieldValueValidation,
    buildFieldValuesAttributesRequestData,
    data: { customFields, groupedCustomFields }
  } = useCustomFieldForm({
    applyToSlugs: ['runbook_add_edit', 'runbook_edit'],
    customFieldsLookup,
    fieldValues: runbook.field_values,
    customFieldGroups,
    constraintContext: { runbook_type_id: runbook?.runbook_type_id }
  })

  const defaultValues = useMemo(() => {
    return {
      runbook: {
        ai_generated_description: runbook.ai_generated_description,
        account_id: account?.id || null,
        author_name: runbook.author_name,
        description: runbook.description ?? '',
        field_values: buildDefaultFieldValues(customFields, groupedCustomFields, initialFieldValues),
        name: runbook.name,
        project_id: runbook.project_id,
        role_types: runbook.role_types ?? [],
        roles: runbook.role_types.flatMap(roleType =>
          roleType.users.map(user => ({
            id: user.role_id,
            role_type_id: roleType.id,
            subject_id: user.id,
            subject_type: 'User',
            resource_type: 'Runbook',
            resource_id: runbook?.id
          }))
        ),
        runbook_versions_attributes: [
          {
            start_planned: runbook.current_version?.start_planned
              ? new Date(runbook.current_version?.start_planned * 1000).toISOString()
              : null,
            start_scheduled: runbook.current_version?.start_scheduled
              ? new Date(runbook.current_version?.start_scheduled * 1000).toISOString()
              : null,
            end_scheduled: runbook.current_version?.end_scheduled
              ? new Date(runbook.current_version?.end_scheduled * 1000).toISOString()
              : null,
            end_planned: runbook.current_version?.end_planned
              ? new Date(runbook.current_version?.end_planned * 1000).toISOString()
              : null,
            auto_start: runbook.current_version?.auto_start,
            rto_start_task_id: runbook.current_version?.rto_start_task_id || null,
            rto_end_task_id: runbook.current_version?.rto_end_task_id || null
          }
        ],
        runbook_type_id: runbook.runbook_type_id,
        status: runbook.status,
        status_message: runbook.status_message,
        // Add additional attributes here -- mapped from the runbook's data
        rto: runbook.rto,
        rto_source_id: runbook.rto_source_id,
        timezone: runbook.timezone ?? 'automatic',
        timing_mode: runbook.current_version?.timing_mode ?? null,
        settings_substreams_inherit_color: runbook.settings_substreams_inherit_color,
        settings_task_description_on_task_start: runbook.settings_task_description_on_task_start,
        settings_team_in_task_list: runbook.settings_team_in_task_list,
        settings_lock_template_copies_to_folder: runbook.settings_lock_template_copies_to_folder,
        template_next_review: runbook.template_next_review, // is this actually something that can be udpated? if it is only ever readOnly, then oesn't need to be a form field
        template_status: runbook.template_status
      },
      shift_time: false,
      timezone: runbook.timezone
    }
  }, [runbook])

  const methods = useForm<RunbookEditFormType>({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        runbook: yup.object().shape({
          name: yup.string().required(),
          project_id: yup.number().required(),
          runbook_type_id: yup.number().required(),
          field_values: templateType !== 'snippet' ? fieldValueValidation : yup.object().notRequired(),
          role_types: yup
            .array()
            .of(
              yup.object().shape({
                key: yup.string(),
                users: yup.array().of(yup.object())
              })
            )
            // TODO: i18n
            .test('atLeastOneAdmins', 'At least one admin is required', value =>
              value?.some(
                role_type => role_type.key === 'runbook-admin' && role_type.users && role_type.users.length > 0
              )
            ),
          status: yup.string(),
          status_message: yup
            .string()
            .nullable()
            .when('status', {
              is: (val: string) => val !== 'off',
              then: () => yup.string().required()
            }),
          runbook_versions_attributes: yup.array().of(
            yup.object().shape({
              start_scheduled:
                runbook?.current_version?.timing_mode === 'scheduled'
                  ? yup.string().required()
                  : yup.string().nullable()
            })
          )
        })
      })
    )
  })

  const { formState, reset } = methods

  const errorRef = useRef<HTMLElement>(null)

  const userRoleIdLookup = useMemo(
    () =>
      runbook?.role_types
        .flatMap(roleType => roleType.users)
        .reduce((mapping: { [key: string]: number }, user) => {
          if (!mapping[user.id]) {
            mapping[user.id] = user.role_id
          }
          return mapping
        }, {}),
    [runbook?.role_types]
  )

  useEffect(() => {
    reset(defaultValues)
    setDescriptionGenerated(runbook.ai_generated_description)
  }, [runbook])

  useEffect(() => {
    if (errorMessage && methods.formState?.submitCount) {
      errorRef?.current?.parentElement?.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [errorMessage, errorRef, methods.formState?.submitCount])

  useEffect(() => {
    if (Object.keys(methods.formState?.errors).length > 0) {
      setErrorMessage(t('common:formInvalid'))
    } else {
      setErrorMessage(null)
    }
  }, [methods.formState?.errors])

  useEffect(() => {
    if (apiErrorMessage) {
      setErrorMessage(apiErrorMessage)
    }
  }, [apiErrorMessage])

  const submitForm = (data: RunbookEditFormType) => {
    const roles = data.runbook.role_types.flatMap(roleType => {
      return roleType.users.map(user => ({
        id: (user.id && userRoleIdLookup?.[user.id]) || null,
        role_type_id: roleType.id,
        subject_id: user.id,
        subject_type: 'User',
        resource_type: 'Runbook',
        resource_id: runbook?.id
      }))
    })

    const runbookPayload = {
      ...data.runbook,
      ai_generated_description: descriptionGenerated,
      roles,
      field_values_attributes: buildFieldValuesAttributesRequestData(data.runbook.field_values),
      field_values: undefined,
      id: runbookId,
      runbook_versions_attributes: [
        {
          ...data?.runbook?.runbook_versions_attributes[0],
          id: runbook?.current_version.id
        }
      ],
      timezone: data?.runbook?.timezone === 'automatic' ? null : data.runbook.timezone
    } as RunbookUpdatePayload['runbook']

    onSubmit({
      data,
      payload: runbookPayload,
      timezone: data?.runbook?.timezone === 'automatic' ? null : data?.runbook?.timezone
    })
  }

  const handleSubmit = () => {
    methods.handleSubmit(submitForm)()
  }

  const handleReset = () => {
    setDescriptionGenerated(runbook.ai_generated_description)
    reset(defaultValues)
  }

  const buttonBadge = useMemo(() => {
    return (runbook?.copies_count ?? 0) > 0
      ? {
          label: runbook?.copies_count,
          type: 'primary' as const
        }
      : undefined
  }, [runbook?.copies_count])

  const onClickViewRunbook = () => {
    closeRightPanel()

    // TODO: remove when runbook is in react
    if (location.pathname.includes('timeline')) {
      const searchObject = queryString.parse(location.search)
      localStorage.setItem('previousTargetState', 'app.root.runbooks.timeline')
      localStorage.setItem('previousQuery', JSON.stringify(searchObject))
    }

    if (account) navigate(toRunbook({ accountSlug: account.slug, runbookId }))
  }

  const footerButton = (
    <Button
      label={
        templateType === 'default'
          ? t('viewTemplate')
          : templateType === 'snippet'
          ? t('viewSnippet')
          : t('viewRunbook')
      }
      icon="arrow-forward"
      primary
      full
      onClick={onClickViewRunbook}
    />
  )

  const panelHeaderItems = useMemo(
    () => [
      ...(runbook && runbook.copies_count > 0
        ? [
            <IconButton
              icon="copies"
              tertiary
              label={t('viewCopies')}
              badge={buttonBadge}
              onClick={() => openRightPanel({ type: 'runbook-copies', runbook })}
              key="runbook-copies"
            />
          ]
        : []),
      ...(runbook && !isEmpty(runbook.linked_runbook_details)
        ? [
            <IconButton
              icon="link"
              tertiary
              label={t('linkedRunbooks')}
              onClick={() => openRightPanel({ type: 'runbook-linked', runbook })}
              key="runbook-linked"
            />
          ]
        : [])
    ],
    [runbook, buttonBadge]
  )

  const { errors, isSubmitSuccessful, isDirty } = formState
  const isError = !isEmpty(formState.errors)
  const isTemplateLocked = useIsTemplateLocked(runbook?.current_version?.approval_status)
  const isFormDisabled =
    (isSubmitSuccessful && isEmpty(errors) && !isRunbookPage) || isTemplateLocked || runbook.archived
  const isFormSubmitting = errorMessage || isRunbookPage ? isSubmitting : isSubmitSuccessful

  return (
    <FormProvider {...methods}>
      <EditPanel
        onClose={closeRightPanel}
        isError={isError}
        isDirty={isDirty}
        isSubmitting={isFormSubmitting}
        loading={isLoading}
        onReset={handleReset}
        title={
          templateType === 'default'
            ? t('title.template')
            : templateType === 'snippet'
            ? t('title.snippet')
            : t('title.runbook')
        }
        onSubmit={handleSubmit}
        footer={!isRunbookPage && footerButton}
        headerItems={panelHeaderItems}
      >
        {errorMessage && (
          <Box margin={{ bottom: '10px' }} height={{ min: 'auto' }} ref={errorRef}>
            <Message message={errorMessage} type="error" />
          </Box>
        )}
        {account && (
          <RunbookEditForm
            taskLookup={taskLookup}
            taskTypeLookup={taskTypeLookup}
            streamLookup={streamLookup}
            disabled={isFormDisabled}
            key={runbook.id}
            readOnly={readOnly}
            customFieldGroupsLookup={customFieldGroupsLookup}
            customFields={customFields}
            customFieldsByGroupId={groupedCustomFields}
            customFieldUsers={customFieldUsers}
            runbook={runbook}
            runbookType={runbookType}
            runbookTypes={runbookTypes}
            projects={permittedProjectsData.projects}
            account={account}
            descriptionGenerated={descriptionGenerated}
            setDescriptionGenerated={setDescriptionGenerated}
          />
        )}
      </EditPanel>
    </FormProvider>
  )
}
